<template>
  <validation-observer
    ref="usersForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form
      autocomplete="off"
      @submit.prevent="handleSubmit(createUser)"
    >
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="true"
        :can-cancel="false"
      />

      <!-- page header component -->
      <page-header
        name="add user"
        icon="account"
      >
        <template #actions>
          <div class="level-item">
            <b-field>
              <b-button
                :disabled="invalid"
                native-type="submit"
                type="is-success"
                icon-left="save"
              >
                create user
              </b-button>
            </b-field>
          </div>
        </template>
      </page-header>

      <!-- users form component -->
      <section class="section">
        <div class="container">
          <!-- error message component -->
          <b-message
            v-model="isInvalid"
            type="is-warning"
            has-icon
          >
            {{ message }}
          </b-message>
          <div class="columns">
            <div class="column is-three-fifths">
              <users-form />
            </div>

            <div class="column">
              <div class="card is-primary">
                <header class="card-header">
                  <p class="card-header-title">
                    membership
                  </p>
                </header>

                <div class="card-content">
                  <users-membership-form :roles="roles" />
                </div>
              </div>
            </div>
          </div>

          <b-field>
            <b-button
              :disabled="invalid"
              native-type="submit"
              type="is-success"
              icon-left="save"
              value="create user"
            >
              create user
            </b-button>
          </b-field>
        </div>
      </section>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { UsersForm, UsersMembershipForm } from '@/components/Organization';
import { PageHeader } from '@/components/Shared';

import UsersMixin from '@/mixins/UsersMixin';

export default {

  name: 'AddUser',

  components: {
    ValidationObserver,
    UsersForm,
    UsersMembershipForm,
    PageHeader,
  },

  mixins: [UsersMixin],

  methods: {

    async createUser() {
      this.isLoading = true;
      const payload = { ...this.user };
      delete payload.division;
      delete payload.manager;
      delete payload.team;
      try {
        this.isInvalid = false;
        await this.$store.dispatch('Users/createUser', payload);
        await this.$router.push('/organization?activeTab=1');
      } catch (error) {
        this.message = error.message;
        this.isInvalid = true;
        console.error(error);
      } finally {
        this.isLoading = true;
      }
    },

  },
};
</script>

<style lang="css" scoped>
</style>
