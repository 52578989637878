<template>
  <div>
    <!-- loading component -->
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
    />

    <validation-observer
      ref="usersForm"
      v-slot="{ invalid, handleSubmit }"
    >
      <form
        autocomplete="off"
        @submit.prevent="handleSubmit(updateUser)"
      >
        <!-- page header  -->
        <page-header
          name="edit user"
          icon="account"
        >
          <template #actions>
            <div class="level-item">
              <b-field>
                <b-switch
                  v-model="isActive"
                  type="is-info"
                >
                  active
                </b-switch>
              </b-field>
            </div>

            <div class="level-item">
              <b-field>
                <b-button
                  :disabled="invalid"
                  native-type="submit"
                  type="is-success"
                  icon-left="save"
                >
                  update user
                </b-button>
              </b-field>
            </div>
          </template>
        </page-header>

        <!-- users form component -->
        <section class="section">
          <div class="container">
            <div class="columns">
              <div class="column is-three-fifths">
                <users-form />
              </div>

              <div class="column">
                <div class="card is-primary is-spaced-bottom">
                  <header class="card-header">
                    <p class="card-header-title">
                      membership
                    </p>
                  </header>

                  <div class="card-content">
                    <users-membership-form :roles="roles" />
                  </div>
                </div>
              </div>
            </div>

            <b-field>
              <b-button
                :disabled="invalid"
                native-type="submit"
                type="is-success"
                icon-left="save"
                value="update user"
              >
                update user
              </b-button>
            </b-field>
          </div>
        </section>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

import { UsersForm, UsersMembershipForm } from '@/components/Organization';
import { PageHeader } from '@/components/Shared';

import UsersMixin from '@/mixins/UsersMixin';

export default {
  name: 'EditUser',

  components: {
    ValidationObserver,
    UsersForm,
    UsersMembershipForm,
    PageHeader,
  },

  mixins: [UsersMixin],

  computed: {
    isActive: {
      get() {
        return this.$store.state.Users.user.isActive;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_IS_ACTIVE', value);
      },
    },
  },

  created() {
    this.$store.dispatch('Users/fetchUsers');
  },

  mounted() {
    this.$store.commit('Organization/SET_USER_ID', this.$route.params.userId);
  },

  methods: {
    async createGoal() {
      this.isLoading = true;
      const payload = { ...this.goal };
      try {
        if (payload.recurrenceIntervalId === 'quarterly') {
          payload.amount = '';
        } else {
          payload.quarterly = {
            q1: '',
            q2: '',
            q3: '',
            q4: '',
          };
        }
        await this.$store.dispatch('Users/createGoal', payload);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async updateUser() {
      this.isLoading = true;
      const payload = { ...this.user };
      delete payload.manager;
      try {
        const data = {
          userId: this.$route.params.userId,
          ...payload,
        };
        if (data.userPhoto) {
          if (data.userPhoto.startsWith('http')) {
            data.userPhoto = '';
          }
        } else {
          data.userPhoto = '';
        }
        delete data.password;
        await this.$store.dispatch('Users/updateUser', data);
        await this.$router.push('/organization?activeTab=1');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
